import {
  request,
} from 'services/api';
import {
  sendRequest,
} from 'utils/httpClient';
import {
  LoggedInUserInfoDTO,
} from 'dtos/loggedInUserInfoDTO';
import {
  MultiMediaRequestDTO,
  MultiMediaResponseDTO,
  MultiMediaResponse,
  UserSettingDTO,
  PatientDTO,
  VisitNotePageResponseDTO,
  EligibilityRequestDTO,
  CheckEligibilitySuccessResponse,
  ProviderDTO,
} from 'dtos';

import {
  formatSearchPatient,
} from 'pages/Dashboard/pages/Charts/helper';
import {
  DosespotNotifications,
} from 'pages/Dashboard/types/dosespot';
import {
  Patient,
  PaginatedPatientList,
  SearchPatientsDataParams,
  RequestMultiMediaDataParams,
} from 'pages/Dashboard/types/patient';
import isFunction from 'lodash/isFunction';
import {
  SearchPatientResultDTOPaginatedResponseDTO,
} from 'dtos/searchPatientResultDTOPaginatedResponseDTO';
import {
  postApiMultimediaGetPatientMultiMedia,
} from 'endpoints';

function normalizeMultiMediaResponseDTO(dto: MultiMediaResponseDTO) {
  return dto?.list ?? [];
}

export const getSearchPatients = async ({
  pagination,
  searchQuery,
  setTotalSize,
  requestMedia,
}: SearchPatientsDataParams): Promise<Patient[]> => {
  if (searchQuery.trimStart() === '') return [];
  const patients = await request<SearchPatientResultDTOPaginatedResponseDTO>(
    '/Patient/SearchPatients',
    {
      method: 'GET',
      params: {
        searchText: searchQuery,
        page: pagination?.pageIndex ?? 0,
        pageSize: pagination?.pageSize ?? 50,
      },
    },
  );

  if (isFunction(setTotalSize)) {
    setTotalSize(patients.pageProperties?.totalSize ?? 0);
  }
  if (isFunction(requestMedia)) {
    const ids = patients?.data?.map(({ patientId }) => patientId) as number[];
    requestMedia(ids);
  }

  return (patients.data ?? []).map(formatSearchPatient);
};

export const getPatients = async ({
  searchQuery,
  page,
  pageSize,
}: {searchQuery: string; page: number; pageSize?: number}): Promise<PaginatedPatientList> => {
  const _patients = await request<SearchPatientResultDTOPaginatedResponseDTO>(
    '/Patient/SearchPatients',
    {
      method: 'GET',
      params: {
        searchText: searchQuery,
        page: page ?? 0,
        pageSize: pageSize ?? 25,
      },
    },
  );
  const patients = (_patients.data ?? []).map(formatSearchPatient);
  return { data: patients, pageProperties: _patients.pageProperties };
};

export const searchVisitNotesByPatientName = (
  searchText?: string,
): Promise<VisitNotePageResponseDTO[]> => (
  request<VisitNotePageResponseDTO[]>(
    '/visitNote/getVisitNotesByPatientName',
    {
      method: 'GET',
      params: { searchText },
    },
  )
);

export const getMultiMedia = (data: MultiMediaRequestDTO): Promise<MultiMediaResponse[]> => (
  request<MultiMediaResponseDTO>(
    '/multimedia/requestMultiMedia',
    { method: 'POST', data },
  ).then(normalizeMultiMediaResponseDTO)
);

export const getMultiMediaWithPagination = (
  data: MultiMediaRequestDTO,
): Promise<MultiMediaResponseDTO> => (
  request<MultiMediaResponseDTO>(
    '/multimedia/requestMultiMedia',
    { method: 'POST', data },
  )
);

export const getDosespotNotifications = (): Promise<DosespotNotifications> => (
  request<DosespotNotifications>('/eRx/UserNotificationCounts', {
    method: 'GET',
  })
);

export const getLoggedUserInfo = (): Promise<LoggedInUserInfoDTO> => (
  request<LoggedInUserInfoDTO>('/User/GetLoggedInUserInfo', { method: 'GET' }));

export function geteRxPage(patientId?: string): Promise<string> {
  const hasPatient = typeof patientId !== 'undefined';
  const url = `eRx/${hasPatient ? 'PatientWebpageURL' : 'UserWebpageURL'}`;
  return request<{ url: string }>(url, {
    method: 'GET',
    params: { patientId },
  }).then((data) => data.url);
}

export function getUserSettings(): Promise<UserSettingDTO> {
  return request<UserSettingDTO>('Settings/GetOrCreateUserSettings', { method: 'GET' });
}

export function upsertUserSettings(data: UserSettingDTO): Promise<UserSettingDTO> {
  return request<UserSettingDTO>('Settings/CreateOrUpdateUserSettings', {
    method: 'POST',
    data,
  });
}

export const getInsurancesByPatientId = (
  patientId: number,
): Promise<PatientDTO> => (
  request<PatientDTO>(
    `/patient/GetPatientInsurancesById/${patientId}?activeOnly=${false}`,
    { method: 'GET' },
  )
);

export const checkEligibility = (data: EligibilityRequestDTO): Promise<
  CheckEligibilitySuccessResponse> => (
  sendRequest<CheckEligibilitySuccessResponse>({ url: '/api/CH/checkEligibility' }, {
    method: 'POST',
    data,
  })
);

export const getReferringProviderFromLastVisit = (
  patientId: number,
): Promise<ProviderDTO> => (
  request<ProviderDTO>('/encounter/getReferringProviderFromLastAppointment', {
    method: 'GET',
    params: { patientId },
  })
);

export const getPatientsMultimedia = async ({
  pagination,
  setTotalSize,
  ...data
}: RequestMultiMediaDataParams): Promise<MultiMediaResponse[]> => {
  const results = await postApiMultimediaGetPatientMultiMedia(
    {
      ...data,
      pageRequest: {
        page: pagination?.pageIndex ?? 0,
        pageSize: pagination?.pageSize ?? 2,
      },
    },
  );

  setTotalSize?.(results?.pageProperties?.totalSize ?? 0);

  return results?.list ?? [];
};

export function getTextFileContent(url: string, enabled: boolean = false) {
  return enabled
    ? sendRequest<string>({
      url,
      method: 'GET',
    }, undefined, { isExternal: true })
    : Promise.resolve(null);
}
