import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import compact from 'lodash/compact';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import {
  Accept,
  useDropzone,
} from 'react-dropzone';
import {
  BillingMultiMediaResponseDTO,
  BillingMultiMediaUploadDTO,
  MultiMediaResponse,
  ClaimBundleDTO,
  MultiMediaResponseDTO,
  EOBWithInsurancePaymentRequestDTO,
  EOBWithInsurancePaymentResponseDTO,
  SplitClaimsDTO,
  CreateSecondaryClaimDTO,
  ClaimAdjustmentReasonCodeDTO,
  ChargeMasterDTO,
  CustomChargeMasterDTO,
  None,
  PatientStatementRequestDTO,
  SettingsDTO,
  EOBDataForClaimViewDTO,
  PatientInvoiceRequestDTO,
  MarkClaimStatusDTO,
  PatientStatementReminderResponseDTO,
  WriteOffRequestDTO,
  BulkPatientStatementGenerationDTO,
  WriteOffByEncounterDTO,
  OnlinePaymentRequestDTO,
  CommunicationResult,
  AppointmentClaimsDTO,
  DeleteRequestDTO,
  ClaimWiseTagRequestDTO,
  BulkPaperClaimsGenerationDTO,
  BulkPaperClaimsTriggerResponseDTO,
} from 'dtos';
import {
  formatISODateTime,
} from 'utils/date';
import {
  MediaCategory,
  MediaOriginatorType,
} from 'pages/Dashboard/utils/constants';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import {
  getMultiMedia,
} from 'pages/Dashboard/services/api';
import {
  uploadBillingMedia,
  deleteClaim,
  createClaim,
  saveClaim,
  getClaimSettings,
  invokeClaimRequest,
  saveClaimSplit,
  getClaimSplitsByEncounter,
  createSecondaryClaim,
  getPatientPaymentFilterSettings,
} from 'pages/Dashboard/pages/Billing/api';
import {
  ClaimSettings,
  ClaimSplitsBundle,
  EOBClaimRequest,
  PatientInvoice,
  PatientStatementRequest,
  Price,
} from 'pages/Dashboard/pages/Billing/types';
import {
  normalizeClaimSplits,
  normalizePatientInvoice,
  paymentNotificationsByVariant,
  serializeEOBWithInsurancePaymentRequest,
  serializePrice,
  serializePatientStatementPayload,
} from 'pages/Dashboard/pages/Billing/helpers';
import {
  useNotify,
} from 'core/hooks';
import {
  deleteApiChargeDeleteChargePolicy,
  deleteApiChargeDeleteCustomCodeChargePolicy,
  getApiClaimSearchAdjustmentReasonCode,
  postApiBillingPrintStatementPdf,
  postApiBillingSendStatementPdf,
  getApiSettingsGetWriteOffSettings,
  postApiChargeCreateChargePolicy,
  postApiChargeCreateCustomCodeChargePolicy,
  postApiChargeModifyChargePolicy,
  postApiChargeModifyCustomCodeChargePolicy,
  postApiClaimClaimRequestResponseStatusClaimId,
  getApiBillingGetEOBByPatientPatientId,
  getApiBillingGetEOB,
  postApiBillingGetPatientStatement,
  postApiBillingPrintInvoicePdf,
  getApiBillingGetStatementLastSentInfo,
  getApiClaimGetClaimByIdClaimId,
  postApiBillingAddOrUpdateWriteOffs,
  postApiBillingGenerateBulkPatientStatement,
  getApiReportDownloadReport,
  getApiBillingDownloadBulkPatientStatement,
  postApiEncounterGetWriteoffsByEncounterId,
  postApiClaimMarkClaimStatus,
  postApiBillingCancelPatientPayment,
  postApiBillingSendBillForOnlinePayment,
  getApiEncounterGetAppointmentClaimsSummary,
  postApiTagUpdateClaimWiseTags,
  getApiTagGetClaimWiseTagsClaimId,
  postApiBillingModifyEOBWithInsurancePayment,
  postApiBillingCreateEOBWithInsurancePayment,
  deleteApiBillingDeleteEOB,
  getApiClaimGetAccidentDatesByPatientIdPatientId,
  postApiBillingMarkAsSecondaryEobIdClaimId,
  postApiBillingShiftToPatientEobIdClaimId,
  postApiClaimGenerateBulkPaperClaims,
  getApiClaimDownloadBulkPaperClaims,
} from 'endpoints';
import {
  normalizeFilterableSettings,
} from 'core/Filters/util';
import {
  ReasonCode,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBAdjustmentCodePopover';
import {
  isEmptyString,
} from 'utils/misc';
import {
  BulkPatientStatementTriggerResponseDTO,
} from 'dtos/bulkPatientStatementTriggerResponseDTO';
import {
  FileResultDTO,
} from 'dtos/fileResultDTO';
import {
  OnDrop,
} from 'pages/Dashboard/components/Dropzone';
import {
  TagDTO,
} from 'dtos/tagDTO';
import uniq from 'lodash/uniq';

const transportFunctionsByType = Object.freeze({
  cpt: {
    create: postApiChargeCreateChargePolicy,
    update: postApiChargeModifyChargePolicy,
    delete: deleteApiChargeDeleteChargePolicy,
  },
  custom: {
    create: postApiChargeCreateCustomCodeChargePolicy,
    update: postApiChargeModifyCustomCodeChargePolicy,
    delete: deleteApiChargeDeleteCustomCodeChargePolicy,
  },
});

export function useUpsertServices():
  UseMutationResult<ChargeMasterDTO | CustomChargeMasterDTO, Error, Price> {
  return useMutation<ChargeMasterDTO | CustomChargeMasterDTO, Error, Price>(
    (data: Price) => {
      const { isCustom, id } = data;
      const { create, update } = transportFunctionsByType[isCustom ? 'custom' : 'cpt'];
      const serialized = serializePrice(data);
      return isNil(id) ? create(serialized) : update(serialized);
    },
  );
}

export function useDeleteChargePolicy(): UseMutationResult<
  ChargeMasterDTO | CustomChargeMasterDTO,
  Error,
  Partial<Price>> {
  return useMutation<
    ChargeMasterDTO | CustomChargeMasterDTO,
    Error,
    Partial<Price>>(async ({ id, isCustom }) => {
      const deleteChargePolicy = isCustom
        ? deleteApiChargeDeleteCustomCodeChargePolicy
        : deleteApiChargeDeleteChargePolicy;
      const response = await deleteChargePolicy({ id });
      return response;
    });
}

export function useUpsertEOB():
  UseMutationResult<EOBWithInsurancePaymentResponseDTO, Error, EOBWithInsurancePaymentRequestDTO> {
  return useMutation<EOBWithInsurancePaymentResponseDTO, Error, EOBWithInsurancePaymentRequestDTO>(
    (data: EOBWithInsurancePaymentRequestDTO) => (
      isNil(data.eob?.eobId) || data.eob?.eobId === 0
        ? postApiBillingCreateEOBWithInsurancePayment(serializeEOBWithInsurancePaymentRequest(data))
        : postApiBillingModifyEOBWithInsurancePayment(serializeEOBWithInsurancePaymentRequest(data))
    ),
  );
}

/// @TODO: replace with general print hook
export function usePrintPayment():
  UseMutationResult<MultiMediaResponse, Error, PatientInvoiceRequestDTO> {
  return useMutation<
    MultiMediaResponse, Error, PatientInvoiceRequestDTO
   >(postApiBillingPrintInvoicePdf);
}

export function useGetInvoiceCommuniqueInfo(
  patientId: number,
  enabled = true,
): UseQueryResult<PatientStatementReminderResponseDTO | void, Error> {
  return useQuery<PatientStatementReminderResponseDTO | void, Error>(
    ['patientInvoiceData', patientId],
    () => getApiBillingGetStatementLastSentInfo({ patientId }),
    { enabled: enabled && patientId > 0 },
  );
}

export function useDeleteEOB(): UseMutationResult<None, Error, number> {
  return useMutation<None, Error, number>((id: number) => deleteApiBillingDeleteEOB({ id }));
}

export function useGetEOBDataForBilling(
  id: number,
  dateRange: Date[],
): UseQueryResult<
  EOBDataForClaimViewDTO[], Error> {
  const [startDate, endDate] = dateRange.map((date) => formatISODateTime(date));
  return useQuery<EOBDataForClaimViewDTO[], Error>(
    ['eob-claims-data', id, ...dateRange],
    () => getApiBillingGetEOBByPatientPatientId(id, {
      startDate,
      endDate,
    }),
    { enabled: id > 0 },
  );
}

export function useUploadBillingMedia(): UseMutationResult<
  BillingMultiMediaResponseDTO,
  Error,
  BillingMultiMediaUploadDTO> {
  return useMutation<
    BillingMultiMediaResponseDTO,
    Error,
    BillingMultiMediaUploadDTO>(uploadBillingMedia);
}

export function useMarkAsSecondaryClaimType(): UseMutationResult<
  None, Error, EOBClaimRequest> {
  return useMutation<None, Error, EOBClaimRequest>(
    ({ eobId, claimId }) => postApiBillingMarkAsSecondaryEobIdClaimId(eobId, claimId),
  );
}

export function useGetBillingMediaByCategories(
  id: number,
  categories: MediaCategory[],
): UseQueryResult<MultiMediaResponse[], Error> {
  return useQuery<MultiMediaResponse[], Error>(
    ['multimedia', id, ...categories],
    () => getMultiMedia({
      generateUrls: true,
      list: categories.map((category) => ({
        originatorType: MediaOriginatorType.Billing,
        originatorId: id,
        mediaCategory: category,
      })),
    }),
    { enabled: id > 0 && categories.length > 0 },
  );
}

export function useCreateClaim(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(createClaim);
}

export function useClaimSettings(): ClaimSettings {
  const { data } = usePrefetchedData<ClaimSettings>({
    key: 'claim-settings',
    fetchFn: getClaimSettings,
  });

  return data ?? {};
}

export function useDeleteClaim(): UseMutationResult<void, Error, number> {
  return useMutation<void, Error, number>(deleteClaim);
}

export function useGetClaimById(id: number = 0):
  UseQueryResult<ClaimBundleDTO, Error> {
  return useQuery<ClaimBundleDTO, Error>(
    ['claim', id],
    () => getApiClaimGetClaimByIdClaimId(id),
    { enabled: id > 0 },
  );
}

export function useGetClaimSplitsByEncounter(
  id: number = 0,
): UseQueryResult<ClaimSplitsBundle, Error> {
  return useQuery<ClaimSplitsBundle, Error>(
    ['claim', 'splits', id],
    async () => {
      const response = await getClaimSplitsByEncounter(id);
      return {
        ...response,
        splitClaims: normalizeClaimSplits(response),
      };
    },
    { enabled: id > 0 },
  );
}

export function useSaveClaim(): UseMutationResult<ClaimBundleDTO, Error, ClaimBundleDTO> {
  return useMutation<ClaimBundleDTO, Error, ClaimBundleDTO>(saveClaim);
}

export function useMarkClaimStatus(): UseMutationResult<ClaimBundleDTO, Error, MarkClaimStatusDTO> {
  return useMutation<ClaimBundleDTO, Error, MarkClaimStatusDTO>(postApiClaimMarkClaimStatus);
}

export function useCheckClaimStatus(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(postApiClaimClaimRequestResponseStatusClaimId);
}

export function useSaveSplitClaim(): UseMutationResult<void, Error, SplitClaimsDTO> {
  return useMutation<void, Error, SplitClaimsDTO>(saveClaimSplit);
}

export function useCreateSecondaryClaim(): UseMutationResult<
  ClaimBundleDTO, Error, CreateSecondaryClaimDTO> {
  return useMutation<ClaimBundleDTO, Error, CreateSecondaryClaimDTO>(createSecondaryClaim);
}

export function useClaimRequest(): UseMutationResult<ClaimBundleDTO, Error, number> {
  return useMutation<ClaimBundleDTO, Error, number>(invokeClaimRequest);
}

export function useGetClaimTags(claimId: number = 0):
  UseQueryResult<TagDTO[], Error> {
  return useQuery<TagDTO[], Error>(
    ['claim-wise-tags', claimId],
    async () => getApiTagGetClaimWiseTagsClaimId(claimId),
    { enabled: claimId > 0 },
  );
}

export function useUpdateClaimWiseTags():
  UseMutationResult<TagDTO, Error, ClaimWiseTagRequestDTO> {
  return useMutation<TagDTO, Error, ClaimWiseTagRequestDTO>(
    postApiTagUpdateClaimWiseTags,
  );
}

export function useGetAccidentDatesByPatientId(
  patientId: number,
  enabled = true,
): UseQueryResult<string[], Error> {
  return useQuery<string[], Error>(
    ['accident-dates', patientId],
    async () => {
      const response = await getApiClaimGetAccidentDatesByPatientIdPatientId(patientId);
      return uniq(response ?? []);
    },
    { enabled },
  );
}

export function usePatientInvoiceData(
  data: PatientStatementRequestDTO,
): UseQueryResult<PatientInvoice, Error> {
  return useQuery<PatientInvoice, Error>(
    ['patientInvoiceData', data],
    async () => {
      const response = await postApiBillingGetPatientStatement(data);
      return normalizePatientInvoice(response);
    },
  );
}

export function usePrintPatientStatement():
  UseMutationResult<MultiMediaResponseDTO, Error, PatientStatementRequest> {
  return useMutation<MultiMediaResponseDTO, Error, PatientStatementRequest>(
    (data: PatientStatementRequest) => {
      const serialized = serializePatientStatementPayload(data);
      return postApiBillingPrintStatementPdf(serialized);
    },
  );
}

export function useSendBillForOnlinePayment():
  UseMutationResult<CommunicationResult, Error, OnlinePaymentRequestDTO> {
  return useMutation<
    CommunicationResult,
    Error,
    OnlinePaymentRequestDTO
  >(postApiBillingSendBillForOnlinePayment);
}

export function useSendPatientStatement():
  UseMutationResult<None, Error, PatientStatementRequest> {
  return useMutation<None, Error, PatientStatementRequest>(
    (data: PatientStatementRequest) => {
      const serialized = serializePatientStatementPayload(data);
      return postApiBillingSendStatementPdf(serialized);
    },
  );
}

export function useUpdateWriteOff(): UseMutationResult<None, Error, WriteOffRequestDTO> {
  return useMutation<None, Error, WriteOffRequestDTO>(postApiBillingAddOrUpdateWriteOffs);
}

/// @TODO: ERROR is suppose to be properly handled by ReactQueryProvider
export function useNotifyPaymentUpdate() {
  const notify = useNotify();
  return (isComplete?: boolean, isCancelled?: boolean) => {
    const alertVariant = isComplete
      ? 'success' : 'error';

    const infoVariant = isCancelled ? 'info' : null;

    notify(paymentNotificationsByVariant[isNil(infoVariant) ? alertVariant : infoVariant]);
  };
}

export function useSearchAdjustmentReasonCode(
  searchString: string,
  enabled = true,
): UseQueryResult<ReasonCode[], Error> {
  return useQuery<ReasonCode[], Error>(
    ['adjustment-reason-codes', searchString],
    async () => {
      const response = await getApiClaimSearchAdjustmentReasonCode({ searchString });
      return ((response ?? []) as ClaimAdjustmentReasonCodeDTO[])
        .map(({ description, reasonCode }) => ({
          code: reasonCode ?? undefined,
          description,
        }));
    },
    { enabled },
  );
}

export function useGetPatientPaymentFilterSettings() {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'patient-payment-filter-settings',
    fetchFn: getPatientPaymentFilterSettings,
  });

  return normalizeFilterableSettings(data?.filterableSettings ?? []);
}

export function useWriteOffOptions(): string[] {
  const { data } = usePrefetchedData<SettingsDTO>({
    key: 'payment-metadata',
    fetchFn: getApiSettingsGetWriteOffSettings,
  });

  return compact(map(data?.writeOffSettings ?? [], 'reason'));
}

export function useGetEOBByNumber(
  eobNumber?: string | null,
): UseQueryResult<EOBWithInsurancePaymentResponseDTO, Error> {
  return useQuery(
    ['eob', eobNumber],
    () => getApiBillingGetEOB({ eobNumber: eobNumber ?? undefined }),
    {
      enabled: !isEmptyString(eobNumber),
      keepPreviousData: true,
    },
  );
}

export function usePrepareStatement(): UseMutationResult<
  BulkPatientStatementTriggerResponseDTO,
  Error,
  BulkPatientStatementGenerationDTO
  > {
  return useMutation<
    BulkPatientStatementTriggerResponseDTO,
    Error,
    BulkPatientStatementGenerationDTO
  >(postApiBillingGenerateBulkPatientStatement);
}

export function usePreparePaperClaims(): UseMutationResult<
  BulkPaperClaimsTriggerResponseDTO,
  Error,
  BulkPaperClaimsGenerationDTO
  > {
  return useMutation<
    BulkPaperClaimsTriggerResponseDTO,
    Error,
    BulkPaperClaimsGenerationDTO
  >(postApiClaimGenerateBulkPaperClaims);
}

const downloadTypes = ['report', 'statement', 'bulk paper claims'] as const;
type BulkDownload = typeof downloadTypes[number];

const downloadHandlerByType: Record<
  BulkDownload,
  [(...params: any[]) => Promise<FileResultDTO>, string]> = {
    report: [getApiReportDownloadReport, 'reportId'],
    statement: [getApiBillingDownloadBulkPatientStatement, 'bulkPatientStatementId'],
    'bulk paper claims': [getApiClaimDownloadBulkPaperClaims, 'bulkPaperClaimsId'],
  };

export function useDownloadFile(
  id: number,
  downloadId: string | null,
  type: string = 'report',
) {
  return useQuery<FileResultDTO, Error>(
    [`${type}-download`, id, downloadId],
    () => {
      const [getApi, keyId] = downloadHandlerByType[type as BulkDownload];
      return getApi({ [keyId]: id });
    },
    { enabled: !isNil(downloadId) },
  );
}

export const useFileDropzone = (onDrop?: OnDrop, accept?: Accept) => {
  const { getInputProps, getRootProps } = useDropzone({
    accept: isNil(accept) ? {
      'image/*': ['.png', '.jpg', '.jpeg', '.tiff'],
      'application/pdf': ['.pdf'],
    } : accept,
    multiple: false,
    onDrop,
  });

  return {
    getInputProps,
    getRootProps,
  };
};

export function useGetWriteOffs(encounterId: number) {
  return useQuery<WriteOffByEncounterDTO[], Error>(
    ['write-offs', encounterId],
    () => postApiEncounterGetWriteoffsByEncounterId({ encounterId }),
  );
}

export function useGetAppointmentClaimsSummary(encounterId: number, enabled: boolean = true) {
  return useQuery<AppointmentClaimsDTO, Error>(
    ['appointment-claims-summary', encounterId],
    () => getApiEncounterGetAppointmentClaimsSummary({ encounterId }),
    { enabled: encounterId > 0 && enabled },
  );
}

export function useCancelPayment(): UseMutationResult<None, Error, DeleteRequestDTO> {
  return useMutation<None, Error, DeleteRequestDTO>(postApiBillingCancelPatientPayment);
}

export function useShiftToPatient(): UseMutationResult<None, Error, EOBClaimRequest> {
  return useMutation<None, Error, EOBClaimRequest>(
    ({ eobId, claimId }) => postApiBillingShiftToPatientEobIdClaimId(eobId, claimId),
  );
}
